export default {
  // 菲律宾语
  word1001: 'Filipino',
  word1002: 'Mail',
  word1003: 'password',
  word1004: 'nakalimutan ang password?',
  word1005: 'Mag log in',
  word1006: 'magparehistro',
  word1007: 'Kasunduan ng User',
  word1008: 'personal na privacy',
  word1009: 'tungkol sa atin',
  word1010: 'Hindi maaaring walang laman ang account',
  word1011: 'hindi maaaring blangko ang password',
  word1012: 'Na-freeze ang account na ito',
  word1013: 'Maling username o password',
  word1014: 'matagumpay ang pag-login',
  word1015: 'Palayaw',
  word1016: 'Ilagay ang numero sa kanan',
  word1017: 'kumuha ng verification code',
  word1018: 'ulitin',
  word1019: 'Hindi maaaring walang laman ang numero',
  word1020: 'Maling verification code',
  word1021: 'Dalawang password ay hindi pare-pareho',
  word1022: 'Ang palayaw ay dapat punan',
  word1023: 'Hindi maaaring walang laman ang e-mail',
  word1024: 'Ang paulit-ulit na password ay hindi maaaring walang laman',
  word1025: 'Subukang muli sa ilang segundo',
  word1026: 'tagumpay sa pagpaparehistro',
  word1027: 'E-mail verification code',
  word1028: 'dapat punan ang verification code',
  word1029: 'Bagong Password',
  word1030: 'palitan ANG password',
  word1031: 'Hindi maaaring walang laman ang bagong password',
  word1032: 'Matagumpay na nabago',
  AccountPlaceholder: 'Mail',
  AccountEmptyTip: 'Hindi maaaring walang laman ang email account',
  PasswordPlaceholder: 'password',
  PasswordEmptyTip: 'hindi maaaring blangko ang password',
  AccountForbidden: 'ang akawnt na ito ay di na magagamit',
  AccountPasswordError: 'Mali ang mail o password',
  RealName: 'aktwal na pangalan',
  RealNameEmptyTip: 'Huwag iwanang blangko ang tunay na pangalan',
  RightNumber: 'Pakilagay ang numero sa larawan sa kanan',
  NumberEmptyTip: 'Ang numero sa larawan ay hindi maaaring walang laman',
  EmailPlaceholder: 'Mail',
  EmailEmptyTip: 'Hindi maaaring walang laman ang email account',
  EmailError: 'Mali ang format ng email',
  GetEmailCode: 'Kumuha ng email verification code',
  Retry: 'Subukan muli sa ilang segundo',
  Resend: 'Muling ipadala',
  EmailCodePlaceholder: 'E-mail verification code',
  EmailCodeEmptyTip: 'Hindi maaaring walang laman ang email verification code',
  NewPassword: 'Bagong Password',
  InputNewPassword: 'Mangyaring magpasok ng bagong password',
  AgainInputNewPassword: 'Mangyaring magpasok muli ng bagong password',
  PasswordLength: 'Ang haba ng password ay nasa pagitan ng 8 at 16 na character',
  EmailHasRegister:
    'Paumanhin, ang email address na ito ay nairehistro na, mangyaring gumamit ng isa pang email address',
  EmailHasExist: 'Umiiral na o natanggal na ang mailbox ng user',
  EmailCodeInvalid: 'Ang email verification code ay hindi wasto, mangyaring kunin itong muli',
  EmailCodeError: 'Error sa verification code sa email',
  Confirm: 'Oo naman',
  AccountInexistence: 'Ang account na ito ay hindi umiiral at ang password ay hindi maaaring baguhin.',
  EnterPassword: 'Mangyaring ipasok ang iyong password',
  ConfirmPassword: 'Mangyaring iulit ang iyong password'
}
