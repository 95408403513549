export default {
  // 英语
  word1001: 'English',
  word1002: 'E-mail',
  word1003: 'Password',
  word1004: 'Forget password？',
  word1005: 'Login',
  word1006: 'Register',
  word1007: 'User Agreement',
  word1008: 'Privacy',
  word1009: 'About Us',
  word1010: 'Account cannot be empty',
  word1011: 'Password cannot be empty',
  word1012: 'The account has been frozen',
  word1013: 'Account or password error',
  word1014: 'Successful Login',
  word1015: 'Nickname',
  word1016: 'Input numbers on the right',
  word1017: 'Get the Code',
  word1018: 'Repeat the password',
  word1019: 'Cannot be blank',
  word1020: 'Incorrect numbers',
  word1021: 'The entries do not match. Please re-enter',
  word1022: 'Cannot be blank',
  word1023: 'Cannot be blank',
  word1024: 'Cannot be blank',
  word1025: ' s to retry',
  word1026: 'Registration succeeded',
  word1027: 'The verification code from E-mail',
  word1028: 'Cannot be blank',
  word1029: 'New Password',
  word1030: 'Change Password',
  word1031: 'Cannot be blank',
  word1032: 'Your password has been changed successfully!',

  // 新增
  AccountPlaceholder: 'Email',
  AccountEmptyTip: 'Please enter the Email',
  PasswordPlaceholder: 'Password',
  PasswordEmptyTip: 'Please enter the password',
  AccountForbidden: 'The account has been disabled',
  AccountPasswordError: 'Incorrect email or password',
  RealName: 'User Name',
  RealNameEmptyTip: 'Please enter the User Name',
  RightNumber: 'Please enter the numbers on the right',
  NumberEmptyTip: 'Please enter the numbers on the right',
  EmailPlaceholder: 'Email',
  EmailEmptyTip: 'Please enter the Email address',
  EmailError: 'Please enter the correct Email address',
  GetEmailCode: 'Get Verification Code',
  Retry: ' s to resend',
  Resend: 'Resend',
  EmailCodePlaceholder: 'Please enter the Email verification code',
  EmailCodeEmptyTip: 'Email verification code cannot be empty',
  NewPassword: 'New Password',
  InputNewPassword: 'Please enter the new password',
  AgainInputNewPassword: 'Please re-enter your new password',
  PasswordLength: 'Password length should be between 8 and 16 characters',
  EmailHasRegister: 'Sorry, this email has already been registered. Please use another email.',
  EmailHasExist: 'User email already exists or has been deleted',
  EmailCodeInvalid: 'Invalid email verification code, please obtain again',
  EmailCodeError: 'Incorrect email verification code',
  Confirm: 'Confirm',
  AccountInexistence: 'The account does not exist, and the password cannot be modified.',
  EnterPassword: 'Please enter your password',
  ConfirmPassword: 'Please enter your password again'
}
