export default {
  // 老挝语
  word1001: 'Lao',
  word1002: 'ຈົດໝາຍ',
  word1003: 'ລະຫັດຜ່ານ',
  word1004: 'ລືມລະຫັດຜ່ານ?',
  word1005: 'ເຂົ້າ​ສູ່​ລະ​ບົບ',
  word1006: 'ລົງທະບຽນ',
  word1007: 'ຂໍ້ຕົກລົງຜູ້ໃຊ້',
  word1008: 'ຄວາມເປັນສ່ວນຕົວ',
  word1009: 'ກ່ຽວ​ກັບ​ພວກ​ເຮົາ',
  word1010: 'ບັນຊີບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  word1011: 'ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  word1012: 'ບັນຊີນີ້ຖືກຈຶ້ງແລ້ວ',
  word1013: 'ຊື່ຜູ້ໃຊ້ ຫຼືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
  word1014: 'ເຂົ້າສູ່ລະບົບສຳເລັດແລ້ວ',
  word1015: 'ຊື່ Nick',
  word1016: 'ໃສ່ຕົວເລກຢູ່ເບື້ອງຂວາ',
  word1017: 'ໄດ້ຮັບລະຫັດຢືນຢັນ',
  word1018: 'ຊ້ຳ',
  word1019: 'ຕົວເລກບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  word1020: 'ລະຫັດຢືນຢັນບໍ່ຖືກຕ້ອງ',
  word1021: 'ສອງລະຫັດຜ່ານບໍ່ສອດຄ່ອງກັນ',
  word1022: 'ຄວນໃສ່ຊື່ຫຼິ້ນໃຫ້ເຕັມ',
  word1023: 'ອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  word1024: 'ລະຫັດຜ່ານຊໍ້າຄືນບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  word1025: 'ລອງອີກຄັ້ງໃນວິນາທີ',
  word1026: 'ການລົງທະບຽນສົບຜົນສໍາເລັດ',
  word1027: 'ລະຫັດຢືນຢັນອີເມລ',
  word1028: 'ຕ້ອງຕື່ມລະຫັດຢືນຢັນ',
  word1029: 'ລະຫັດຜ່ານໃໝ່',
  word1030: 'ປ່ຽນ​ລະ​ຫັດ​ຜ່ານ',
  word1031: 'ລະຫັດຜ່ານໃໝ່ບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  word1032: 'ແກ້ໄຂສຳເລັດແລ້ວ',
  AccountPlaceholder: 'ຈົດໝາຍ',
  AccountEmptyTip: 'ບັນຊີອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  PasswordPlaceholder: 'ລະຫັດຜ່ານ',
  PasswordEmptyTip: 'ລະຫັດຜ່ານບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  AccountForbidden: 'ບັນຊີນີ້ຖືກປິດໃຊ້ງານ',
  AccountPasswordError: 'ອີເມວ ຫຼືລະຫັດຜ່ານບໍ່ຖືກຕ້ອງ',
  RealName: 'ຊື່​ແທ້',
  RealNameEmptyTip: 'ຢ່າປ່ອຍໃຫ້ຫວ່າງສໍາລັບຊື່ແທ້',
  RightNumber: 'ກະລຸນາໃສ່ຕົວເລກໃນຮູບເບື້ອງຂວາ',
  NumberEmptyTip: 'ຕົວເລກໃນຮູບບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  EmailPlaceholder: 'ຈົດໝາຍ',
  EmailEmptyTip: 'ບັນຊີອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  EmailError: 'ຮູບແບບອີເມວບໍ່ຖືກຕ້ອງ',
  GetEmailCode: 'ເອົາລະຫັດຢືນຢັນອີເມວ',
  Retry: 'ລອງອີກຄັ້ງໃນວິນາທີ',
  Resend: 'ສົ່ງຄືນ',
  EmailCodePlaceholder: 'ລະຫັດຢືນຢັນອີເມລ',
  EmailCodeEmptyTip: 'ລະຫັດຢືນຢັນອີເມວບໍ່ສາມາດຫວ່າງເປົ່າໄດ້',
  NewPassword: 'ລະຫັດຜ່ານໃໝ່',
  InputNewPassword: 'ກະລຸນາໃສ່ລະຫັດຜ່ານໃໝ່',
  AgainInputNewPassword: 'ກະລຸນາໃສ່ລະຫັດຜ່ານໃໝ່ອີກຄັ້ງ',
  PasswordLength: 'ຄວາມຍາວຂອງລະຫັດຜ່ານແມ່ນຢູ່ລະຫວ່າງ 8 ຫາ 16 ຕົວອັກສອນ',
  EmailHasRegister: 'ຂໍອະໄພ, ທີ່ຢູ່ອີເມວນີ້ໄດ້ຖືກລົງທະບຽນແລ້ວ, ກະລຸນາໃຊ້ທີ່ຢູ່ອີເມວອື່ນ',
  EmailHasExist: 'ກ່ອງຈົດໝາຍຂອງຜູ້ໃຊ້ມີຢູ່ແລ້ວ ຫຼືຖືກລຶບໄປແລ້ວ',
  EmailCodeInvalid: 'ລະຫັດຢືນຢັນອີເມວບໍ່ຖືກຕ້ອງ, ກະລຸນາຮັບມັນອີກຄັ້ງ',
  EmailCodeError: 'ລະຫັດຢືນຢັນອີເມວຜິດພາດ',
  Confirm: 'ແນ່ນອນ',
  AccountInexistence: 'ບັນຊີນີ້ບໍ່ມີຢູ່ ແລະບໍ່ສາມາດປ່ຽນລະຫັດຜ່ານໄດ້.',
  EnterPassword: 'ກະລູນາໃສ່ລະຫັດຜ່ານທີ່ມີ',
  ConfirmPassword: 'ກະລູນາກົດລະຫັດຜ່ານອີກຄັ້ງ'
}
