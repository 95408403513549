export default {
  // 马来语
  localLanguage: 'Malay',
  word1001: 'Burma',
  word1002: 'Mel',
  word1003: 'kata laluan',
  word1004: 'lupa kata laluan?',
  word1005: 'Log masuk',
  word1006: 'mendaftar',
  word1007: 'Perjanjian Pengguna',
  word1008: 'privasi peribadi',
  word1009: 'tentang kita',
  word1010: 'Akaun tidak boleh kosong',
  word1011: 'kata laluan tidak boleh kosong',
  word1012: 'Akaun ini telah dibekukan',
  word1013: 'Nama pengguna atau kata laluan salah',
  word1014: 'log masuk berjaya',
  word1015: 'nama panggilan',
  word1016: 'Masukkan nombor di sebelah kanan',
  word1017: 'dapatkan kod pengesahan',
  word1018: 'ulang',
  word1019: 'Nombor tidak boleh kosong',
  word1020: 'Kod pengesahan yang salah',
  word1021: 'Dua kata laluan tidak konsisten',
  word1022: 'Nama panggilan harus diisi',
  word1023: 'E-mel tidak boleh kosong',
  word1024: 'Kata laluan ulangan tidak boleh kosong',
  word1025: 'Cuba lagi dalam beberapa saat',
  word1026: 'pendaftaran berjaya',
  word1027: 'Kod pengesahan e-mel',
  word1028: 'kod pengesahan mesti diisi',
  word1029: 'Kata laluan baharu',
  word1030: 'tukar kata laluan',
  word1031: 'Kata laluan baharu tidak boleh kosong',
  word1032: 'Berjaya diubah suai',
  AccountPlaceholder: 'Mel',
  AccountEmptyTip: 'Akaun e-mel tidak boleh kosong',
  PasswordPlaceholder: 'kata laluan',
  PasswordEmptyTip: 'kata laluan tidak boleh kosong',
  AccountForbidden: 'Akaun ini telah dilumpuhkan',
  AccountPasswordError: 'Mel atau kata laluan tidak betul',
  RealName: 'nama sebenar',
  RealNameEmptyTip: 'Jangan biarkan kosong untuk nama sebenar',
  RightNumber: 'Sila masukkan nombor dalam gambar di sebelah kanan',
  NumberEmptyTip: 'Nombor dalam gambar tidak boleh kosong',
  EmailPlaceholder: 'Mel',
  EmailEmptyTip: 'Akaun e-mel tidak boleh kosong',
  EmailError: 'Format e-mel salah',
  GetEmailCode: 'Dapatkan kod pengesahan e-mel',
  Retry: 'Cuba lagi dalam beberapa saat',
  Resend: 'Hantar semula',
  EmailCodePlaceholder: 'Kod pengesahan e-mel',
  EmailCodeEmptyTip: 'Kod pengesahan e-mel tidak boleh kosong',
  NewPassword: 'Kata laluan baharu',
  InputNewPassword: 'Sila masukkan kata laluan baharu',
  AgainInputNewPassword: 'Sila masukkan kata laluan baharu sekali lagi',
  PasswordLength: 'Panjang kata laluan adalah antara 8 dan 16 aksara',
  EmailHasRegister: 'Maaf, alamat e-mel ini telah didaftarkan, sila gunakan alamat e-mel lain',
  EmailHasExist: 'Peti mel pengguna sudah wujud atau telah dipadamkan',
  EmailCodeInvalid: 'Kod pengesahan e-mel tidak sah, sila dapatkan sekali lagi',
  EmailCodeError: 'Ralat kod pengesahan e-mel',
  Confirm: 'pasti',
  AccountInexistence: 'Akaun ini tidak wujud dan kata laluan tidak boleh ditukar.',
  EnterPassword: 'Sila masukkan kata laluan anda',
  ConfirmPassword: 'Sila masukkan kata laluan anda sekali lagi'
}
