export default {
  // 泰语
  word1001: 'Thai',
  word1002: 'จดหมาย',
  word1003: 'รหัสผ่าน',
  word1004: 'ลืมรหัสผ่านเหรอ?',
  word1005: 'เข้าสู่ระบบ',
  word1006: 'ลงทะเบียน',
  word1007: 'ข้อตกลงการใช้',
  word1008: 'ความเป็นส่วนตัว',
  word1009: 'เกี่ยวกับเรา',
  word1010: 'บัญชีต้องไม่ว่างเปล่า',
  word1011: 'รหัสผ่านต้องไม่เว้นว่าง',
  word1012: 'บัญชีนี้ถูกระงับ',
  word1013: 'ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง',
  word1014: 'เข้าสู่ระบบสำเร็จ',
  word1015: 'ชื่อนิค',
  word1016: 'ใส่หมายเลขทางด้านขวา',
  word1017: 'รับรหัสยืนยัน',
  word1018: 'ทำซ้ำ',
  word1019: 'หมายเลขต้องไม่เว้นว่าง',
  word1020: 'รหัสยืนยันไม่ถูกต้อง',
  word1021: 'รหัสผ่านสองตัวไม่สอดคล้องกัน',
  word1022: 'ควรกรอกชื่อเล่น',
  word1023: 'อีเมลไม่สามารถเว้นว่างได้',
  word1024: 'รหัสผ่านซ้ำไม่สามารถเว้นว่างได้',
  word1025: 'ลองอีกครั้งในไม่กี่วินาที',
  word1026: 'ความสำเร็จในการลงทะเบียน',
  word1027: 'รหัสยืนยันอีเมล',
  word1028: 'ต้องกรอกรหัสยืนยัน',
  word1029: 'รหัสผ่านใหม่',
  word1030: 'เปลี่ยนรหัสผ่าน',
  word1031: 'รหัสผ่านใหม่ไม่สามารถเว้นว่างได้',
  word1032: 'แก้ไขเรียบร้อยแล้ว',
  AccountPlaceholder: 'จดหมาย',
  AccountEmptyTip: 'บัญชีอีเมลต้องไม่ว่างเปล่า',
  PasswordPlaceholder: 'รหัสผ่าน',
  PasswordEmptyTip: 'รหัสผ่านต้องไม่เว้นว่าง',
  AccountForbidden: 'บัญชีนี้ถูกปิดการใช้งาน',
  AccountPasswordError: 'เมลหรือรหัสผ่านไม่ถูกต้อง',
  RealName: 'ชื่อจริง',
  RealNameEmptyTip: 'ห้ามเว้นว่างไว้เป็นชื่อจริง',
  RightNumber: 'กรุณากรอกตัวเลขในภาพด้านขวา',
  NumberEmptyTip: 'หมายเลขในภาพต้องไม่เว้นว่าง',
  EmailPlaceholder: 'จดหมาย',
  EmailEmptyTip: 'บัญชีอีเมลต้องไม่ว่างเปล่า',
  EmailError: 'รูปแบบอีเมลไม่ถูกต้อง',
  GetEmailCode: 'รับรหัสยืนยันอีเมล',
  Retry: 'ลองอีกครั้งในไม่กี่วินาที',
  Resend: 'ส่งซ้ำ',
  EmailCodePlaceholder: 'รหัสยืนยันอีเมล',
  EmailCodeEmptyTip: 'รหัสยืนยันอีเมลไม่สามารถเว้นว่างได้',
  NewPassword: 'รหัสผ่านใหม่',
  InputNewPassword: 'กรุณากรอกรหัสผ่านใหม่',
  AgainInputNewPassword: 'กรุณากรอกรหัสผ่านใหม่อีกครั้ง',
  PasswordLength: 'รหัสผ่านมีความยาวระหว่าง 8 ถึง 16 ตัวอักษร',
  EmailHasRegister: 'ขออภัย ที่อยู่อีเมลนี้ได้ถูกลงทะเบียนแล้ว โปรดใช้ที่อยู่อีเมลอื่น',
  EmailHasExist: 'กล่องจดหมายของผู้ใช้มีอยู่แล้วหรือถูกลบไปแล้ว',
  EmailCodeInvalid: 'รหัสยืนยันอีเมลไม่ถูกต้อง กรุณารับใหม่อีกครั้ง',
  EmailCodeError: 'ข้อผิดพลาดรหัสยืนยันอีเมล',
  Confirm: 'แน่นอน',
  AccountInexistence: 'ไม่มีบัญชีนี้และไม่สามารถเปลี่ยนรหัสผ่านได้',
  EnterPassword: 'โปรดป้อนรหัสผ่านของคุณ',
  ConfirmPassword: 'โปรดป้อนรหัสผ่านของคุณอีกครั้ง'
}
