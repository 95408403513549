export default {
  // 越南语
  word1001: 'Vietnamese',
  word1002: 'Thư',
  word1003: 'mật khẩu',
  word1004: 'quên mật khẩu?',
  word1005: 'Đăng nhập',
  word1006: 'đăng ký',
  word1007: 'sự thỏa thuận của người dùng',
  word1008: 'quyền riêng tư cá nhân',
  word1009: 'về chúng tôi',
  word1010: 'Tài khoản không thể trống',
  word1011: 'mật khẩu không thể để trống',
  word1012: 'Tài khoản này đã bị đóng băng',
  word1013: 'Tên đăng nhập hoặc mật khẩu không chính xác',
  word1014: 'đăng nhập thành công',
  word1015: 'tên nick',
  word1016: 'Nhập số bên phải',
  word1017: 'lấy mã xác minh',
  word1018: 'lặp lại',
  word1019: 'Số không thể trống',
  word1020: 'Mã xác minh không chính xác',
  word1021: 'Hai mật khẩu không nhất quán',
  word1022: 'Biệt danh phải được điền',
  word1023: 'Email không được để trống',
  word1024: 'Mật khẩu lặp lại không được để trống',
  word1025: 'Hãy thử lại sau vài giây',
  word1026: 'đăng ký thành công',
  word1027: 'Mã xác minh email',
  word1028: 'mã xác minh phải được điền',
  word1029: 'mật khẩu mới',
  word1030: 'đổi mật khẩu',
  word1031: 'Mật khẩu mới không được để trống',
  word1032: 'Đã sửa đổi thành công',
  AccountPlaceholder: 'Thư',
  AccountEmptyTip: 'Tài khoản email không được để trống',
  PasswordPlaceholder: 'mật khẩu',
  PasswordEmptyTip: 'mật khẩu không thể để trống',
  AccountForbidden: 'Tài khoản này đã bị vô hiệu hóa',
  AccountPasswordError: 'Thư hoặc mật khẩu không đúng',
  RealName: 'tên thật',
  RealNameEmptyTip: 'Đừng để trống tên thật',
  RightNumber: 'Vui lòng nhập số ở hình bên phải',
  NumberEmptyTip: 'Số trong hình không được để trống',
  EmailPlaceholder: 'Thư',
  EmailEmptyTip: 'Tài khoản email không được để trống',
  EmailError: 'Định dạng email sai',
  GetEmailCode: 'Nhận mã xác minh email',
  Retry: 'Hãy thử lại sau vài giây',
  Resend: 'Gửi lại',
  EmailCodePlaceholder: 'Mã xác minh email',
  EmailCodeEmptyTip: 'Mã xác minh email không được để trống',
  NewPassword: 'mật khẩu mới',
  InputNewPassword: 'Vui lòng nhập mật khẩu mới',
  AgainInputNewPassword: 'Vui lòng nhập lại mật khẩu mới',
  PasswordLength: 'Độ dài mật khẩu từ 8 đến 16 ký tự',
  EmailHasRegister: 'Xin lỗi, địa chỉ email này đã được đăng ký, vui lòng sử dụng địa chỉ email khác',
  EmailHasExist: 'Hộp thư của người dùng đã tồn tại hoặc đã bị xóa',
  EmailCodeInvalid: 'Mã xác minh email không hợp lệ, vui lòng lấy lại',
  EmailCodeError: 'Lỗi mã xác minh email',
  Confirm: 'Chắc chắn',
  AccountInexistence: 'Tài khoản này không tồn tại và mật khẩu không thể thay đổi.',
  EnterPassword: 'Vui lòng nhập mật khẩu của bạn',
  ConfirmPassword: 'Vui lòng nhập lại mật khẩu của bạn'
}
