export default {
  // 高棉语
  word1001: 'Khmer',
  word1002: 'សំបុត្រ',
  word1003: 'ពាក្យសម្ងាត់',
  word1004: 'ភ្លេចពាក្យសម្ងាត់?',
  word1005: 'ចូល',
  word1006: 'ចុះឈ្មោះ',
  word1007: 'កិច្ចព្រមព្រៀងអ្នកប្រើប្រាស់',
  word1008: 'ឯកជនភាពផ្ទាល់ខ្លួន',
  word1009: 'អំពី​ពួក​យើង',
  word1010: 'គណនីមិនអាចទទេបានទេ។',
  word1011: 'ពាក្យសម្ងាត់មិនអាចទទេបានទេ។',
  word1012: 'គណនីនេះត្រូវបានបង្កក',
  word1013: 'ឈ្មោះអ្នកប្រើ ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
  word1014: 'ចូលបានជោគជ័យ',
  word1015: 'ឈ្មោះនីក',
  word1016: 'បញ្ចូលលេខនៅខាងស្តាំ',
  word1017: 'ទទួលបានលេខកូដផ្ទៀងផ្ទាត់',
  word1018: 'ធ្វើម្តងទៀត',
  word1019: 'លេខមិនអាចទទេបានទេ។',
  word1020: 'លេខកូដផ្ទៀងផ្ទាត់មិនត្រឹមត្រូវ',
  word1021: 'ពាក្យសម្ងាត់ពីរមិនជាប់គ្នា។',
  word1022: 'ឈ្មោះហៅក្រៅគួរតែត្រូវបានបំពេញ',
  word1023: 'អ៊ីមែលមិនអាចទទេបានទេ។',
  word1024: 'ពាក្យសម្ងាត់ម្តងទៀតមិនអាចទទេបានទេ។',
  word1025: 'ព្យាយាមម្តងទៀតក្នុងរយៈពេលប៉ុន្មានវិនាទីទៀត។',
  word1026: 'ការចុះឈ្មោះជោគជ័យ',
  word1027: 'លេខកូដផ្ទៀងផ្ទាត់អ៊ីមែល',
  word1028: 'លេខកូដផ្ទៀងផ្ទាត់ត្រូវតែបំពេញ',
  word1029: 'ពាក្យសម្ងាត់​ថ្មី',
  word1030: 'ផ្លាស់ប្តូរពាក្យសម្ងាត់',
  word1031: 'ពាក្យសម្ងាត់ថ្មីមិនអាចទទេបានទេ។',
  word1032: 'កែប្រែដោយជោគជ័យ',
  AccountPlaceholder: 'សំបុត្រ',
  AccountEmptyTip: 'គណនីអ៊ីមែលមិនអាចទទេបានទេ។',
  PasswordPlaceholder: 'ពាក្យសម្ងាត់',
  PasswordEmptyTip: 'ពាក្យសម្ងាត់មិនអាចទទេបានទេ។',
  AccountForbidden: 'គណនីនេះត្រូវបានបិទ',
  AccountPasswordError: 'អ៊ីមែល ឬពាក្យសម្ងាត់មិនត្រឹមត្រូវ',
  RealName: 'ឈ្មោះពិត',
  RealNameEmptyTip: 'កុំទុកឲ្យទទេសម្រាប់ឈ្មោះពិត',
  RightNumber: 'សូមបញ្ចូលលេខក្នុងរូបភាពនៅខាងស្តាំ',
  NumberEmptyTip: 'លេខក្នុងរូបភាពមិនអាចទទេបានទេ។',
  EmailPlaceholder: 'សំបុត្រ',
  EmailEmptyTip: 'គណនីអ៊ីមែលមិនអាចទទេបានទេ។',
  EmailError: 'ទម្រង់អ៊ីមែលខុស',
  GetEmailCode: 'ទទួលបានលេខកូដផ្ទៀងផ្ទាត់អ៊ីមែល',
  Retry: 'ព្យាយាមម្តងទៀតក្នុងរយៈពេលប៉ុន្មានវិនាទីទៀត។',
  Resend: 'ផ្ញើឡើងវិញ',
  EmailCodePlaceholder: 'លេខកូដផ្ទៀងផ្ទាត់អ៊ីមែល',
  EmailCodeEmptyTip: 'លេខកូដផ្ទៀងផ្ទាត់អ៊ីមែលមិនអាចទទេបានទេ។',
  NewPassword: 'ពាក្យសម្ងាត់​ថ្មី',
  InputNewPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មី។',
  AgainInputNewPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់ថ្មីម្តងទៀត',
  PasswordLength: 'ប្រវែងពាក្យសម្ងាត់គឺចន្លោះពី 8 ទៅ 16 តួអក្សរ',
  EmailHasRegister: 'សូមអភ័យទោស អាសយដ្ឋានអ៊ីមែលនេះត្រូវបានចុះឈ្មោះរួចហើយ សូមប្រើអាសយដ្ឋានអ៊ីមែលផ្សេងទៀត។',
  EmailHasExist: 'ប្រអប់សំបុត្ររបស់អ្នកប្រើមានរួចហើយ ឬត្រូវបានលុបចេញ',
  EmailCodeInvalid: 'លេខកូដផ្ទៀងផ្ទាត់អ៊ីមែលមិនត្រឹមត្រូវទេ សូមទទួលវាម្តងទៀត',
  EmailCodeError: 'កំហុស​កូដ​ផ្ទៀងផ្ទាត់​អ៊ីមែល',
  Confirm: 'ប្រាកដ',
  AccountInexistence: 'គណនីនេះមិនមានទេ ហើយពាក្យសម្ងាត់មិនអាចផ្លាស់ប្តូរបានទេ។',
  EnterPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់របស់អ្នក',
  ConfirmPassword: 'សូមបញ្ចូលពាក្យសម្ងាត់របស់អ្នកម្តងទៀត។'
}
